<template>
  <div>
    <div class='gooey'>
      <span class='position-absolute' style='z-index: 4000;left:50%; top:50%; transform: translate(-50%,-50%); opacity:90%;'>⏳</span>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    name: 'loadingSpinner'
  };
</script>


<style scoped lang="scss">
  .animate-flicker {
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
  }

  .gooey{
    width: 37px; height: 34px;
    margin: 0 auto;
    background-image: linear-gradient(120deg, #34e0f0 0%, #b400ff 100%);
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    animation: morph 3s linear infinite; 
    transform-style: preserve-3d;
    outline: 1px solid transparent;
    will-change: border-radius;
  }
  .gooey:before,
  .gooey:after{
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0; top: 0;
    border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
    box-shadow: 5px 5px 89px rgba(0, 102, 255, 0.21);
    will-change: border-radius, transform, opacity;
    animation-delay: 200ms;
    background-image: linear-gradient(120deg, rgba(0,67,255,.55) 0%, rgba(0,103,255,.89) 100%);
  }

  .gooey:before{
    animation: morph 3.1s linear infinite;
    opacity: .21;
    animation-duration: 1.5s;
  }

  .gooey:after{
    animation: morph 3.2s linear infinite;
    animation-delay: 400ms;
    opacity: .59;
    content: "";
    line-height: 120px;
    text-indent: -21px;
  }

  $scale: 1.1;

  @keyframes morph{
    0%,100%{
      border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
      transform: translate3d(0,0,0) rotateZ(0.01deg) scale($scale + 0.1);
      filter: hue-rotate(60deg);
    }
    34%{
      border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
      transform:  translate3d(0,5px,0) rotateZ(0.01deg) scale($scale);
      filter: hue-rotate(0deg);
    }
    50%{
      opacity: .89;
      transform: translate3d(0,0,0) rotateZ(0.01deg) scale($scale - 0.1);
      filter: hue-rotate(-60deg);
    }
    67%{
      border-radius: 100% 60% 60% 100% / 100% 100% 60% 60% ;
      transform: translate3d(0,-3px,0) rotateZ(0.01deg) scale($scale);
      filter: hue-rotate(0deg);
    }
  }
</style>
