<template>
  <span>
    <span class="price"><span class="price-eth" v-if="compact">Ξ</span>{{ value | to2Dp }}<span class="price-eth" v-if="!compact"> ETH</span></span>
  </span>
</template>

<script>
  export default {
    name: 'priceInEth',
    props: ['value', 'compact']
  };
</script>

<style scoped lang="scss">
  @import "../../ko-colours.scss";

  .price {
    color: $realBlack;
  }

  .price-eth {
    font-size: .85rem;
    color: $secondary;
  }
</style>
