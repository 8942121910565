<template>
  <span v-if="ethAddress">
    <a class="btn btn-outline-primary btn-sm" :href="buildLink" target="_blank">{{ label || (ethAddress | dotDotDot) }}</a>
  </span>
</template>

<script>
  import {  mapState } from 'vuex';

  export default {
    name: 'clickableAddressButton',
    components: {},
    props: {
      ethAddress: {
        type: String
      },
      label: {
        type: String
      }
    },
    computed: {
      ...mapState([
        'etherscanBase',
      ]),
      buildLink: function () {
        return `${this.etherscanBase}/address/${this.ethAddress}`;
      }
    }
  };
</script>

<style>

</style>
