<template>
  <div>

    <div class="mt-3 text-center" v-if="loading">
      <loading-spinner></loading-spinner>
    </div>

    <div class="row pt-5 pb-5 justify-content-center text-center" v-if='Object.values(nrContracts).length > 0'>
      <div class="col" style='max-width: 600px'>

        <h3>NotReal Contracts</h3>

        <table class="table">
          <tbody>
          <tr v-for='contract in nrContracts' :key='contract._address' v-if='contract'>
            <td>
              {{contract.name.replace('V2', '')}}
            </td>
            <td style='text-decoration: underline'>
              <!--<a :href='`${etherscanBase}/address/${contract._address}`'></a>-->
              <clickable-address :eth-address="contract._address" :keep-internal="false"></clickable-address>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row pt-5 pb-5 justify-content-center text-center" v-if='Object.values(swContracts).length > 0'>
      <div class="col" style='max-width: 600px'>

        <h3>Gnosis Safe Contracts</h3>

        <table class="table">
          <tbody>
          <tr v-for='(address, name) in swContracts' :key='address'>
            <td>
              {{name}}
            </td>
            <td style='text-decoration: underline'>
              <!--<a :href='`${etherscanBase}/address/${contract._address}`'></a>-->
              <clickable-address :eth-address="address" :keep-internal="false"></clickable-address>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row pt-5 pb-5 justify-content-center text-center">
      <div class="col" style='max-width: 600px'>

        <h3>Github Repositories</h3>

        <table class="table">
          <tbody>
          <tr v-for='(url, name) in github' :key='url'>
            <td>
              {{name}}
            </td>
            <td style='text-decoration: underline'>
              <a :href='`https://github.com/${url}`'>{{url}}</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>

  import ClickableAddress from '../components/generic/ClickableAddress';
  import ClickableAddressButton from '../components/generic/ClickableAddressButton';
  import {mapGetters, mapState} from 'vuex';
  import LoadingSpinner from '../components/generic/LoadingSpinner';
  import PriceInEth from '../components/generic/PriceInEth';
  import _ from 'lodash';

  const contracts = [
    'NotRealDigitalAssetV2',
    'TokenMarket',
    //'ArtistAcceptingBids',
    //'ArtistEditionControls',
    //'SelfServiceEditionCuration'
  ]

  export default {
    name: 'contractDetails',
    components: {
      PriceInEth,
      LoadingSpinner,
      ClickableAddressButton,
      ClickableAddress
    },
    data() {
      return {
        loading: false,
        github: {
          'Not Real Contracts': 'NotRealAI/notreal-contracts',
          'Gnosis Contract Proxy Kit': 'gnosis/contract-proxy-kit',
          'Gnosis Safe Contracts': 'gnosis/safe-contracts',
          'Biconomy Mexa SDK': 'bcnmy/mexa-sdk',
          'Matic Proof-of-Stake Bridge': 'maticnetwork/pos-portal'
        }
      };
    },
    computed: {
      ...mapState([
        'TokenMarket',
        'NotRealDigitalAssetV2',
        'etherscanBase',
        'smartwallet'
      ]),
      swContracts() {
        if(!this.smartwallet) { return {} }
        const {contractManager} = this.smartwallet;
        if(!contractManager) { return {} }
        return {
            MasterSafe: contractManager.masterCopyAddress,
            ProxyFactory: _.get(contractManager, 'proxyFactory.contract._address'),
            MultiSend: _.get(contractManager, 'multiSend.contract._address'),
            FallbackHandler: contractManager.fallbackHandlerAddress
          }
      },
      nrContracts() {
        return _.pickBy(_.pick(this, ['TokenMarket', 'NotRealDigitalAssetV2']), _.identity)
      }
    }
  };
</script>

<style scoped lang="scss">

  /* mobile only */
  @media screen and (max-width: 767px) {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
  }
</style>
